<template >

  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col sm="12" lg="4">
          <h3 class="mb-0 title">Distributors 
          
          <button class="btn btn-sm btn-primary add-user-sm" @click="showAddUserModal()">
            <span><i class="fas fa-plus"></i></span>
          </button></h3>
        </b-col>
        <b-col sm="8" lg="4">
            <b-form-group id="search-wrapper" label-for="input-1" v-bind:class="{ active: isActive, focused: isFocused }">
              <b-form-input
                    id="filter-input"
                    ref="filterinput"
                    type="text"
                    placeholder="Search Distributors"
                    @keyup="filterTableBySearch"
                    @blur.native="removeCancelSearchIcon"
                    @focus="focused"
                  ></b-form-input>
                  <span class="clear-search" @click="cancelFilterBySearch()">
                    <i class="fas fa-times"></i>
                  </span>
          </b-form-group>
        </b-col>
        <b-col class="text-right add-user-ds" md="4" lg="4">
          <button class="btn btn-sm btn-primary" @click="showAddUserModal()">
            <span class="">Add Distributor</span>
          </button>
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>

    <!-- Table -->

    <el-table class="table-responsive table"
              :data="displayData"
              header-row-class-name="thead-light"
              v-if="!isLoading" :key="keyChange" @sort-change="sortChange">
      <el-table-column label="Distributor"
                       min-width="160px"
                       sortable
                       prop="Name">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.Name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="Devices"
                       sortable
                       min-width="160px"
                       prop="devices">
      </el-table-column>
      <el-table-column label="Transactions"
                       sortable
                       min-width="160px"
                       prop="totalTransactions">
      </el-table-column>
      <el-table-column label="" min-width="125px">
        <template v-slot="{row}">
        <div class="row-buttons">
            <button class="btn btn-sm btn-primary" :data-id="row.Id" @click="showModal(row.Name, row.Id)">
              <span class="button-text">Add Device</span>
              <span class="button-icon"><i class="fas fa-tablet-alt"></i></span>
            </button>
            <button class="btn btn-sm btn-primary" :data-id="row.Id" @click="showUnlinkModal(row.Name, row.Id)">
              <span class="button-text">Unlink</span>
              <span class="button-icon"><i class="fas fa-unlink"></i></span>
            </button>
            <button class="btn btn-sm btn-primary" :data-id="row.Id" @click="showEditModal(row.Name, row.Id)">
              <span class="button-text">Edit</span>
              <span class="button-icon"><i class="fas fa-pen"></i></span>
            </button>
            <button class="btn btn-sm btn-primary" :data-id="row.Id" @click="showDisableModal(row.Name, row.Id)">
              <span class="button-text">Disable</span>
              <span class="button-icon"><i class="far fa-stop-circle"></i></span>
            </button>
        </div>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="currentPage"
                   @current-change="handleCurrentChange"
                   :total="rows"
                   :page-size="pageSize"
                   align="right"
                   v-if="!isLoading">
    </el-pagination>


    <!-- Add Distributor device -->
    <modal :show="isModalVisible" @close="closeModal">
      <div class="form" v-if="!loading && success == null">
        <p style="font-weight: 700">Add a device for <span style="color: #f7941d;">{{ selectedName }}</span></p>
        <b-form class="form" @submit.prevent="addDistributorDevice" >
          <b-form-group id="input-group-1" label="" label-for="input-1">
            <b-form-input id="input-1"
                          v-model="serialNumber"
                          type="text"
                          placeholder="Enter serial number  "
                          required></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Done</b-button>
          <b-button @click="closeModalHandler" type="button" variant="primary cancel">Cancel</b-button>
        </b-form>
      </div>
      <div class="success-message" v-if="loading">
        <spinner v-if="loading"></spinner>
      </div>
      <div class="success-message" v-if="success">
        <div class="icon-wrapper">
          <i class="fas fa-check"></i>
        </div>
        <p >Device was added successfully!</p>
      </div>
    </modal>

    <!-- Unlink confirmation modal -->
    <modal :show="unlinkModalVisible" @close="closeModal">
      <div class="form">
        <p style="font-weight: 700">Select device to unlink from <span style="color: #f7941d;">{{ selectedName }}</span></p>
        <div>
          <ul class="devices" v-if="!loading && success == null && devices.length > 0">
            <li v-for="device in devices" :key="device.id" class="device">
              {{ device.serialNumber }} <button @click="unlinkDevice(device.Id, device.supplierId , device.serialNumberHash)"><i class="fa fa-trash"></i></button>
            </li>
          </ul>
          <div class="message" v-if="devices.length == 0 && !loading">
            <div class="message-icon">
              <i class="fas fa-exclamation"></i>
            </div>
            <span class="">No devices found.</span>
          </div>
          <div class="success-message" v-if="loading">
            <spinner v-if="loading"></spinner>
          </div>
        </div>
        <b-button @click="closeModalHandler" type="submit" variant="primary">Done</b-button>
      </div>
    </modal>

    <!-- Disable distributor confirmation modal -->
    <modal :show="disableModalVisible" @close="closeModal">
      <div class="form">
        <div>
          <div class="message delete">
            <div class="message-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <span class="">Disabling will prevent login of the supplier linked account.</span>
          </div>
          <div class="success-message" v-if="loading">
            <spinner v-if="loading"></spinner>
          </div>
        </div>
        <div class="buttons">
          <b-button @click="disableDistributor" type="submit" variant="primary">Confirm</b-button>
        <b-button @click="closeModalHandler" type="button" variant="primary cancel">Cancel</b-button>
        </div>
      </div>
    </modal>

    <!-- Add Distributor -->
    <div class="add-supplier">
      <modal @close="closeModal" content-class="user-modal" :show="addUserModalVisible">
      <b-form class="form" @submit.prevent="onAddSupplierSubmit" v-if="!userLoadingState && userSuccessState == null">
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-1" label="Full Name:" label-for="input-1">
                    <b-form-input id="input-1" v-model="form.name" type="text" placeholder="Enter Full Name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
                <b-form-group id="input-group-1" label="Account Number:" label-for="input-1">
                  <b-form-input id="input-2"
                                v-model="form.accountNumber"
                                type="text"
                                placeholder="Enter Account number"
                                required></b-form-input>
                </b-form-group>
        </div>
        <div class="col-md-12">
          
        <b-form-group id="input-group-1" label="Report Times:" label-for="input-1">
            <div class="row">
              <div class="col-md-12">
                <b-form-select v-model="hour" :options="reportHours" :value="reportHours[0]" @change="getReportTimes"></b-form-select>
              </div>
            </div>
            <div class="report-times" v-if="reportPeriods.length > 0">
              <span class="title">Chosen time(s):</span>
              <div  class="periods">
                  <span v-for="period in reportPeriods" :key="period" class="time">
                    <span class="text">{{ period }}</span> <span class="remove" @click="removeReportPeriod(period)"><i class="far fa-trash-alt"></i></span>
                  </span>
              </div>
            </div>
        </b-form-group>
        </div>
        <div class="col-md-12">
                  <b-form-group id="input-group-1" label="Payment reference:" label-for="input-1">
          <b-form-input id="input-4"
                        v-model="form.paymentReference"
                        type="text"
                        placeholder="Enter payment reference"></b-form-input>
                        
        </b-form-group>
        </div>
        <div class="col-md-12">
                  <b-form-group id="input-group-7" label="Payment channel" label-for="input-1">
          <b-form-select v-model="form.paymentChannelId" :options="channels" :value="channels[0].Id"></b-form-select>
        </b-form-group>

        </div>
        <div class="col-md-12">
          
        <b-form-group id="input-group-8" label="Collection channel:" label-for="input-1">
          <b-form-select v-model="form.collectionChannelId" :options="channels" :value="channels[0].Id"></b-form-select>
        </b-form-group>
        </div>
        <div class="col-md-12">
          
        <b-form-group id="input-group-5"
                      label="Email Address: (separated by a comma for multiple)"
                      label-for="input-3">
          <b-form-input id="input-3"
                        v-model="form.emailAddress"
                        type="text"
                        placeholder="Enter Email Adress"></b-form-input>
        </b-form-group>
        </div>
        <div class="col-md-12">
                  <b-form-group id="input-group-1" label="Fee:" label-for="input-1">
          <b-form-input id="input-1"
                        v-model="form.fee"
                        type="text"
                        placeholder="Enter Fee"
                        @change="validateFee"
                        required></b-form-input>
                        <span class="time-error" v-if="!feeError">Please enter a valid fee amount (2.50, 3.30, 250)</span>
        </b-form-group>
        </div>
        <div class="col-md-12">
                  <b-form-group id="input-group-1" label="Region:" label-for="input-region">
          <b-form-input id="input-region"
                        v-model="form.region"
                        type="text"
                        placeholder="Enter region"></b-form-input>
                        
        </b-form-group>
        </div>

        <div class="col-md-12">
            <b-form-group
              id="input-group-4"
              label="Select Primary Branch:"
              label-for="input-4"
          >
            <b-form-select
              v-model="form.primaryBranchId"
              :options="branches"
              :value="branches[0].Id"
              required
              @change="matchingBranches"
            ></b-form-select>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <span class="time-error" v-if="matchingBranchesError">
              Primary branch and secondary branch should not be the same
            </span>
          </b-form-group>

        </div>
        <div class="col-md-12">
            <b-form-group
              id="input-group-4"
              label="Select Secondary Branch:"
              label-for="input-4"
              required
              @change="matchingBranches"
          >
            <b-form-select
              v-model="form.secondaryBranchId"
              :options="branches"
              :value="branches[0].Id"
            ></b-form-select>
             <span class="time-error" v-if="matchingBranchesError">
              Primary branch and secondary branch should not be the same
            </span>
          </b-form-group>

        </div>
          <div class="col-md-12">
            <b-form-group id="input-group-1" label="Store Manager Name:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerName"
                  type="text"
                  placeholder="Enter Manager Name"
                  required
                  ></b-form-input>
              </b-form-group>
         </div>
         <div class="col-md-12">
            <b-form-group id="input-group-1" label="Store Manager Email:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerEmailAddress"
                  type="email"
                  placeholder="Enter Manager Email"
                  required
                  ></b-form-input>
              </b-form-group>
         </div>
         <div class="col-md-12">
            <b-form-group id="input-group-1" label="Store Manager Contact:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerContactCell"
                  type="text"
                  placeholder="Enter Manager Contact"
                  required
                  ></b-form-input>
              </b-form-group>
         </div>
         <div class="col-md-12">
            <b-form-group id="input-group-1" label="Store Manager Landline:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerContactLandline"
                  type="text"
                  placeholder="Enter Manager Landline"
                  required
                  ></b-form-input>
              </b-form-group>
         </div>
         <div class="col-md-12">
            <b-form-group id="input-group-1" label="Store Physical Address:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storePhysicalAddress"
                  type="text"
                  placeholder="Enter Store Physical Address"
                  required
                  ></b-form-input>
              </b-form-group>
         </div>
         
          <div class="col-md-12">
            <b-form-group label="Contract Status" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="form.contractStatus"
                :options="contractStatusOptions"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
                required
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              id="input-group-4"
              label="Select Bank Name:"
              label-for="input-4"
              required
              >
              <b-form-select
                v-model="form.bankName"
                :options="bankBranches"
                :value="branches[0].Id"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group id="input-group-1" label="Branch Code:" label-for="input-1">
              <b-form-input id="input-1"
                v-model="form.branchCode"
                type="number"
                placeholder="Enter Branch Code"
                @change="validateBranchCode"
                required
                aria-describedby="input-live-help input-live-feedback"
                
                ></b-form-input>
                <!-- This will only be shown if the preceding input has an invalid state -->
                <span class="time-error" v-if="!branchCodeError">
                  Branch code has to be 6 digits long
                </span>
              </b-form-group>
          </div>
      </div>

        <b-button type="submit" variant="primary">
          {{
          "Add distributor"
          }}
        </b-button>
        <b-button @click="closeModalHandler" type="button" variant="primary cancel">Cancel</b-button>
      </b-form>
        <div class="success-message" v-if="userLoadingState">
          <spinner v-if="userLoadingState"></spinner>
        </div>
        <div class="success-message" v-if="userSuccessState">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p >Distributor was added successfully!</p>
        </div>
    </modal>
    </div>

    <!-- Edit Distributor -->
    <modal @close="closeModal" class="user-modal" :show="editModalVisible">
      <b-form class="form" @submit.prevent="onEditUserSubmit" v-if="!userLoadingState && userSuccessState == null">
        <b-form-group id="input-group-1" label="Full Name:" label-for="input-1">
          <b-form-input id="input-1"
                        v-model="form.name"
                        type="text"
                        placeholder="Enter Full Name"
                        required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label="Account Number:" label-for="input-1">
          <b-form-input id="input-2"
                        v-model="form.accountNumber"
                        type="text"
                        placeholder="Enter Account number"
                        required></b-form-input>
        </b-form-group>

                <b-form-group id="input-group-1" label="Report Times:" label-for="input-1">
            <div class="row">
              <div class="col-md-12">
                <b-form-select v-model="hour" :options="reportHours" :value="reportHours[0]" @change="getReportTimes"></b-form-select>
              </div>
              
            </div>
            <div class="report-times" v-if="reportPeriods.length > 0">
              <span class="title">Chosen time(s):</span>
              <div  class="periods">
                  <span v-for="period in reportPeriods" :key="period" class="time">
                    <span class="text">{{ period }}</span> <span class="remove" @click="removeReportPeriod(period)"><i class="far fa-trash-alt"></i></span>
                  </span>
              </div>
            </div>
        </b-form-group>

        <b-form-group id="input-group-1" label="Payment reference:" label-for="input-1">
          <b-form-input id="input-4"
                        v-model="form.supplierreference"
                        type="text"
                        placeholder="Enter payment reference"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-7" label="Payment channel" label-for="input-1">
          <b-form-select v-model="form.paymentChannelId" :options="channels" :value="channels[0].Id"></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-8" label="Collection channel:" label-for="input-1">
          <b-form-select v-model="form.collectionChannelId" :options="channels" :value="channels[0].Id"></b-form-select>
        </b-form-group>
        <b-form-group id="input-group-5"
                      label="Email Address: (seperated by comma for multiple)"
                      label-for="input-3">
          <b-form-input id="input-3"
                        v-model="form.emailAddress"
                        type="text"
                        placeholder="Enter Email Adress"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="Fee:" label-for="input-1">
        <b-form-input id="input-1"
                        v-model="form.fee"
                        type="text"
                        placeholder="Enter Fee"
                        @change="validateFee"
                        required></b-form-input>
                        <span class="time-error" v-if="!feeError">Please enter a valid fee amount (2.50, 3.30, 250)</span>
        </b-form-group>

         
                  <b-form-group id="input-group-1" label="Region:" label-for="input-region">
          <b-form-input id="input-region"
                        v-model="form.region"
                        type="text"
                        placeholder="Enter region"></b-form-input>
                        
        </b-form-group>
     

        
            <b-form-group
              id="input-group-4"
              label="Select Primary Branch:"
              label-for="input-4"
          >
            <b-form-select
              v-model="form.primaryBranchId"
              :options="branches"
              :value="branches[0].Id"
              required
              @change="matchingBranches"
            ></b-form-select>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <span class="time-error" v-if="matchingBranchesError">
              Primary branch and secondary branch should not be the same
            </span>
          </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Select Secondary Branch:"
              label-for="input-4"
              required
              @change="matchingBranches"
          >
            <b-form-select
              v-model="form.secondaryBranchId"
              :options="branches"
              :value="branches[0].Id"
            ></b-form-select>
             <span class="time-error" v-if="matchingBranchesError">
              Primary branch and secondary branch should not be the same
            </span>
          </b-form-group>

        
         
            <b-form-group id="input-group-1" label="Store Manager Name:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerName"
                  type="text"
                  placeholder="Enter Manager Name"
                  required
                  ></b-form-input>
              </b-form-group>
      
         
            <b-form-group id="input-group-1" label="Store Manager Email:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerEmailAddress"
                  type="email"
                  placeholder="Enter Manager Email"
                  required
                  ></b-form-input>
            </b-form-group>
        
            <b-form-group id="input-group-1" label="Store Manager Contact:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerContactCell"
                  type="text"
                  placeholder="Enter Manager Contact"
                  required
                  ></b-form-input>
              </b-form-group>
      
         
            <b-form-group id="input-group-1" label="Store Manager Landline:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storeManagerContactLandline"
                  type="text"
                  placeholder="Enter Manager Landline"
                  required
                  ></b-form-input>
              </b-form-group>
      
         
            <b-form-group id="input-group-1" label="Store Physical Address:" label-for="input-1">
                <b-form-input id="input-1"
                  v-model="form.storePhysicalAddress"
                  type="text"
                  placeholder="Enter Store Physical Address"
                  required
                  ></b-form-input>
              </b-form-group>
     
         
          
            <b-form-group label="Contract Status" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="form.contractStatus"
                :options="contractStatusOptions"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
                required
              ></b-form-radio-group>
            </b-form-group>
     
         
            <b-form-group
              id="input-group-4"
              label="Select Bank Name:"
              label-for="input-4"
              required
              >
              <b-form-select
                v-model="form.bankName"
                :options="bankBranches"
                :value="branches[0].Id"
              ></b-form-select>
            </b-form-group>
        
         
            <b-form-group id="input-group-1" label="Branch Code:" label-for="input-1">
              <b-form-input id="input-1"
                v-model="form.branchCode"
                type="number"
                placeholder="Enter Branch Code"
                @change="validateBranchCode"
                required
                aria-describedby="input-live-help input-live-feedback"
                
                ></b-form-input>
                <!-- This will only be shown if the preceding input has an invalid state -->
                <span class="time-error" v-if="!branchCodeError">
                  Branch code has to be 6 digits long
                </span>
              </b-form-group>
           
        <b-button type="submit" variant="primary">Done</b-button>
        <b-button @click="closeModalHandler" type="button" variant="primary cancel">Cancel</b-button>
      </b-form>
      <div class="success-message" v-if="userLoadingState">
          <spinner v-if="userLoadingState"></spinner>
        </div>
        <div class="success-message" v-if="userSuccessState">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p >Distributor was updated successfully!</p>
        </div>
    </modal>
  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Pagination } from 'element-ui'
  import { sha256 } from 'js-sha256';
  import Spinner from '../../components/Spinner.vue';
  import modal from '../../components/Modal.vue';
  import config from '../../config'
  import { helpers } from '../../helpers/index'


  const { reportHours } = helpers;
  
  
  export default {
    name: 'suppliers-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Pagination.name]: Pagination,
      modal,
      Spinner
    },
    data() {
      return {
        isActive: false,
        isFocused: false,
        currentPage: 1,
        pageSize: 10,
        selectedName: '',
        selectedId: '',
        isModalVisible: false,
        unlinkModalVisible: false,
        addUserModalVisible: false,
        disableModalVisible: false,
        editModalVisible: false,
        isLoading: false,
        serialNumber: null,
        paymentChannelId: '',
        collectionChannelId: '',
        masterData: [],
        reportHours,
        reportPeriods: [],
        hour: "",
        minutes: "",
        feeError: true,
        reportTimeValues: "",
        keyChange: 0,
        branchCodeError: true,
        matchingBranchesError: false,
        tableData: [
          {
            "Id": "0",
            "Name": "Loading...",
            "DateCreated": "2021-03-13T09:23:10.000Z",
            "totalTransactions": 0,
            "devices": 0
          },
        ],
        form: {
          name: '',
          accountNumber: '',
          reportTimes: '',
          paymentReference: '',
          paymentChannelId: '',
          collectionChannelId: '',
          supplierreference: '',
          emailAddress: '',
          fee: '',
          permissions: "",
          status: "",
          devices: 0,
          region: "",
          primaryBranchId:"",
          secondaryBranchId: "",
          storeManagerName: "",
          storeManagerEmailAddress:"",
          storeManagerContactCell:"",
          storeManagerContactLandline:"",
          storePhysicalAddress:"",
          contractStatus: "No",
          bankName:"",
          branchCode:""
        },
        
        isUserModalModalVisible: false
      }
    },
    computed: {
      rows() {
        return this.tableData.length;
      },
      displayData() {
        if (!this.tableData || this.tableData.length === 0) return [];
        return this.tableData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
      },
      channels() {
        // Create options array with a default
        const options = [{ value: 0, text: 'Please select a channel' }];
        // Get branches from store
        const channelsData = this.$store.getters['channels/channels'];
        // Make options property for select match API returned values
        if (channelsData.length >= 0) {
          // Sorted channels
          const sorted = JSON.parse(JSON.stringify(channelsData)).sort((a, b) => (a.channelName > b.channelName) ? 1 : -1);
          sorted.forEach(c => {
            // Empty option object
            const option = {}
            // Add value to object
            option.value = c.channelKey;
            // Add key to object
            option.text = c.channelName;

            options.push(option);
          })
        }


        return options;
      },
      loading() {
        return this.$store.getters["devices/isLoading"]
      },
      userLoadingState() {
        return this.$store.getters["suppliers/isLoading"]
      },
      userSuccessState() {
        return this.$store.getters["suppliers/success"]
      },
      userErrorState() {
        return this.$store.getters["suppliers/error"]
      },
      success() {
        return this.$store.getters['devices/success'];
      },
      error() {
        return this.$store.getters['devices/error'];
      },
      devices() {
        return this.$store.getters["devices/devices"];
      },
      reportTimesString() {
        return this.reportTimeValues
      },
      supplier() {
        return this.$store.state.suppliers.distributors;
      },
      branches() {
      // Create options array with a default
      const options = [{ value: 0, text: "Please select a branch" }];
      // Get branches from store
      const branchesData = this.$store.getters["branches/branches"];
      // Make options property for select match API returned values
      if (branchesData.length >= 0) {
        branchesData.forEach(b => {
          const option = {};

          option.value = b.Id;
          option.text = b.Name;

          options.push(option);
        });
      }

      return options;
    },
    bankBranches() {
      const options = [
        { value: 0, text: "Please select a branch" },
        { value: "ABSA", text:"ABSA"},
        {
          value: "FNB",
          text:"FNB"
        },
        {
          value: "NEDBANK",
          text:"NEDBANK"
        }, 
        {
          value: "Standard Bank",
          text:"Standard Bank"
        },
        {
          value: "Investec",
          text:"Investec"
        },
        {
          value: "Capitec",
          text:"Capitec"
        },
      ];
      
      return options;
    },
    contractStatusOptions(){
      const options = [
          { text: 'Yes', value: 'Yes' },
          { text: 'No', value: 'No' }    
        ];

        return options;
      }
    },
    methods: {
      sortChange($event){
        // Get sort change data
        const { prop, order } = $event;
        console.log($event);
        // Check for sort order and table data accordingly
        if(order == "ascending") {
          this.tableData = this.tableData.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
        } 

        if(order == "descending") {
          this.tableData = this.tableData.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
        }
        console.log('Table data:', this.tableData);
      },
      validateFee(value) {
        var isValid = /^[0-9.]*$/.test(value); 
        if(!isValid) {
          this.feeError = false;
        } else {
          this.feeError = true;
        }
      },
      validateBranchCode(value){
        let isValid = value.toString().length == 6 ? true : false;
        this.branchCodeError = isValid;
      },
      matchingBranches() {
        let isValid = this.form.branchId === this.form.secondaryBranchId;
        this.matchingBranchesError = isValid;
      },
      getReportTimes(target) {
        if(this.reportTimeValues == "") {
          // if report time values string is empty we dont add comma in front of value
          this.reportPeriods.push(target);
        } else {
          // Check if string already includes the entered value to avoid duplication
          if(!this.reportTimeValues.includes(target)) {
            // Concatenate selected value to string
            this.reportPeriods.push(target);
          }
        }
      },
      removeReportPeriod(period) {
        // Convert string to JSON
        const formattedReportPeriods = JSON.parse(JSON.stringify(this.reportPeriods));
         // Get index of selected time
         const filteredArray = formattedReportPeriods.filter(p => p !== period);
         // Removed index array
         this.reportPeriods = filteredArray;
      },
      showAddUserModal() {
        this.addUserModalVisible = true
      },
      showModal(name, Id) {
        this.selectedName = name;
        this.selectedId = Id;
        this.isModalVisible = true;
      },
      async showUnlinkModal(name, Id) {
        this.selectedName = name;
        this.selectedId = Id;
        this.unlinkModalVisible = true;
        // Dispatch get user devices actions
        const result = await this.$store.dispatch("devices/getUserDevices", Id);
    
        if(result && result.data) {
          this.$store.dispatch("devices/complete");
        }


      },
      showDisableModal(name, Id) {
        this.selectedName = name;
        this.selectedId = Id;
        this.disableModalVisible = true;
      },
      showEditModal(name, Id) {
        this.selectedName = name;
        this.selectedId = Id;

        // Get current distributor from the store
        const supplier = this.$store.state.suppliers.distributors.find(supplier => supplier.Id == this.selectedId);
        // Format report times string to array
        const formattedReportPeriods = supplier.reportTimes.split(",");
        // Set values as bound in edit form
        this.form.name = supplier.Name;
        this.form.accountNumber = supplier.accountNumber;
        this.form.reportTimes = supplier.ReportTimes;
        this.form.paymentReference = supplier.supplierreference;
        this.form.emailAddress = supplier.emailAddress;
        this.form.paymentChannelId = supplier.paymentChannelId;
        this.form.collectionChannelId = supplier.collectionChannelId;
        this.form.fee = supplier.Fee;
        this.form.supplierreference = supplier.supplierreference;
        this.form.devices = supplier.devices;
        this.reportTimeValues = supplier.reportTimes;
        this.reportPeriods = formattedReportPeriods;
        this.form.region = supplier.region;
        this.form.primaryBranchId = supplier.primaryBranchId;
        this.form.secondaryBranchId = supplier.secondaryBranchId;
        this.form.storeManagerName = supplier.storeManagerName;
        this.form.storeManagerEmailAddress = supplier.storeManagerEmailAddress;
        this.form.storeManagerContactCell = supplier.storeManagerContactCell;
        this.form.storeManagerContactLandline = supplier.storeManagerContactLandline;
        this.form.storePhysicalAddress = supplier.storePhysicalAddress;
        this.form.contractStatus = supplier.contractStatus
        this.form.bankName = supplier.bankName;
        this.form.branchCode = supplier.branchCode;
        
        // Open edit user modal
        this.editModalVisible = true;
      },
      closeModalHandler(unlinkModalVisible) {
        this.unlinkModalVisible = false;
        this.isModalVisible = false;
        this.addUserModalVisible = false;
        this.disableModalVisible = false;
        this.editModalVisible = false;
        this.selectedName = "";
        this.selectedId = null;
        this.serialNumber = "";
        // Reset form fields 
          this.form.name = "";
          this.form.emailAddress = "";
          this.form.accountNumber = "";
          this.form.reportTimes = "";
          this.form.paymentReference = "";
          this.form.paymentChannelId = "";
          this.form.collectionChannelId = "";
          this.reportTimeValues = "";
          this.form.fee = "";
          this.reportPeriods = [];
          this.form.region = "";
          this.form.primaryBranchId = "";
          this.form.secondaryBranchId = "";
          this.form.storeManagerName = "";
          this.form.storeManagerEmailAddress = "";
          this.form.storeManagerContactCell = "";
          this.form.storeManagerContactLandline = "";
          this.form.storePhysicalAddress = "";
          this.form.contractStatus = ""
          this.form.bankName = "";
          this.form.branchCode = null;
       

      },
      closeModal() {
        this.isModalVisible = false;
      },
      handleCurrentChange(val) {
        this.currentPage = val;
      },
      async onEditUserSubmit() {
         // Distributor data
         const supplier = {
           supplierId: this.selectedId,
           Name: this.form.name,
           accountNumber: this.form.accountNumber,
           supplierReference: this.form.supplierreference,
           emailAddress: this.form.emailAddress,
           paymentChannelId: this.form.paymentChannelId,
           collectionChannelId: this.form.collectionChannelId,
           Fee: this.form.fee,
           reportTimes: JSON.parse(JSON.stringify(this.reportPeriods)).toString(),
           region: this.form.region,
          primaryBranchId:this.form.primaryBranchId,
          secondaryBranchId: this.form.secondaryBranchId,
          storeManagerName: this.form.storeManagerName,
          storeManagerEmailAddress:this.form.storeManagerEmailAddress,
          storeManagerContactCell:this.form.storeManagerContactCell,
          storeManagerContactLandline:this.form.storeManagerContactLandline,
          storePhysicalAddress:this.form.storePhysicalAddress,
          contractStatus: this.form.contractStatus,
          bankName:this.form.bankName,
          branchCode:this.form.branchCode
         }

;
        // Dispathc action for adding supplier - 
        const result = await this.$store.dispatch("suppliers/addNewSupplier", supplier);

        // When promise resolved do check and perform other actions
        if(result.data) { 
          setTimeout(async () => {
            // Dispatch an action to refresh devices statev 
            this.$store.dispatch("devices/complete");
            // close modal
            this.editModalVisible = false;
            // Dispatch an action to refresh
            const updatedUsers = await this.$store.dispatch('suppliers/getAllSuppliers');
            // Get suppliers again
            this.tableData = updatedUsers;
            // Reset serial number value in form
            this.serialNumber = "";
            // Clean ID 
            this.selectedId = null;
            // Reset time periods arry
            this.reportPeriods = [];
          }, 1500);
          
          setTimeout(() => {
            // Reset add device state
            this.$store.dispatch("suppliers/userAddedComplete");
          }, 1800);

          // Reset fields
          this.form.name = "";
          this.form.emailAddress = "";
          this.form.accountNumber = "";
          this.form.reportTimes = "";
          this.form.paymentReference = "";
          this.form.paymentChannelId = "";
          this.form.collectionChannelId = "";
          this.reportTimeValues = "";
          this.form.fee = "";
          this.form.region="";
          this.form.primaryBranchId="";
          this.form.secondaryBranchId="";
          this.form.storeManagerName ="";
          this.form.storeManagerEmailAddress="";
          this.form.storeManagerContactCell="";
          this.form.storeManagerContactLandline="";
          this.form.storePhysicalAddress="";
          this.form.contractStatus = "No";
          this.form.bankName ="";
          this.form.branchCode=null;

        }
      },
      async onAddSupplierSubmit() {
        // Use data
        const supplier = {
            Name: this.form.name,
            accountNumber: this.form.accountNumber,
            supplierReference: this.form.supplierreference,
            emailAddress: this.form.emailAddress,
            paymentChannelId: this.form.paymentChannelId,
            collectionChannelId: this.form.collectionChannelId,
            Fee: parseInt(this.form.fee),
            reportTimes: JSON.parse(JSON.stringify(this.reportPeriods)).toString(),
            region: this.form.region,
            primaryBranchId:this.form.primaryBranchId,
            secondaryBranchId: this.form.secondaryBranchId,
            storeManagerName: this.form.storeManagerName,
            storeManagerEmailAddress:this.form.storeManagerEmailAddress,
            storeManagerContactCell:this.form.storeManagerContactCell,
            storeManagerContactLandline:this.form.storeManagerContactLandline,
            storePhysicalAddress:this.form.storePhysicalAddress,
            contractStatus: this.form.contractStatus,
            bankName:this.form.bankName,
            branchCode:this.form.branchCode
        };


        // Dispathc action for adding supplier
        const result = await this.$store.dispatch("suppliers/addNewSupplier", supplier);

        // When promise resolved do check and perform other actions
        if(result.data) { 
          setTimeout(async() => {
             // Reset add device state
            this.$store.dispatch("devices/complete");
            // close modal
            this.addUserModalVisible = false;
            // Get suppliers again
            const updatedSuppliers = await this.$store.dispatch('suppliers/getAllSuppliers');
            // Update table data
            this.tableData = updatedSuppliers
            
            // Reset serial number value in form
            this.serialNumber = "";
            // Clean ID 
            this.selectedId = null;
            // Reset time periods arry
            this.reportPeriods = [];
          }, 1500);
          
          setTimeout(() => {
            // Reset add device state
            this.$store.dispatch("suppliers/userAddedComplete");
          }, 1800);

          // Reset fields
          this.form.name = "";
          this.form.emailAddress = "";
          this.form.accountNumber = "";
          this.form.reportTimes = "";
          this.form.paymentReference = "";
          this.form.paymentChannelId = "";
          this.form.collectionChannelId = "";
          this.reportTimeValues = "";
          this.form.fee = "";
          this.form.region= "";
          this.form.primaryBranchId="";
          this.form.secondaryBranchId= "";
          this.form.storeManagerName= "";
          this.form.storeManagerContactCell="";
          this.form.storeManagerContactLandline="";
          this.form.storeManagerEmailAddress="";
          this.form.storePhysicalAddress="";
          this.form.bankName="";
          this.form.contractStatus= "";
          this.form.branchCode="";

        }


      },
      async unlinkDevice(Id, supplierId, serialNumber) {
        // Dispatch action to unlink device with id
        const result = await this.$store.dispatch("devices/unlinkDeviceFromUser", {
          "Id": Id,
          "supplierId" : supplierId, 
          "serialNumberHash": serialNumber
         });

        if(result.data && result.data == "OK") {
            // Reset add device state
            this.$store.dispatch("devices/complete");
            // close modal
            this.isModalVisible = false;
            // Get suppliers again
            const updatedSuppliers = await this.$store.dispatch('suppliers/getAllSuppliers');
            // Update table data
            this.tableData = updatedSuppliers
            // Reset serial number value in form
            this.serialNumber = "";
       }
      },
      unlinkDistributorDevice() {
        const supplier = JSON.stringify({
          supplierId: this.selectedId
        });
        // Close modal
        setTimeout(() => {
          this.unlinkModalVisible = false;
        }, 1500)
       
      },
      editDistributor() {
        // post to /supplier
      },
      async onAddUserSubmit() { 
        
      },
      async disableDistributor() {
        // Dispatch action that disables user
        const result =  await this.$store.dispatch("suppliers/disableUser", this.selectedId);
        console.log("Result:", result)
        // Do checks 
        if(result.data && result.data == "OK") {
            setTimeout(async () => {
              
              // Get suppliers again
              const updatedSuppliers = await this.$store.dispatch('suppliers/getAllSuppliers');
              // Update table data
              this.tableData = updatedSuppliers
              // Reset serial number value in form
              this.serialNumber = "";
              // Clean ID 
              this.selectedId = null;

              setTimeout(() => {
                // close modal
                this.disableModalVisible = false;
              }, 100)
            }, 500)
        }


      },
      async addDistributorDevice() {

        // Add device here
        const result = await this.$store.dispatch('devices/addDeviceToUser', {
          serial: this.serialNumber,
          serialHash: sha256(this.serialNumber),
          supplierId: this.selectedId
        });

       // Do check on the result

       if(result.data && result.data == "OK") {
          setTimeout(async () => {
            // Dispatch an action to refresh devices statev 
            this.$store.dispatch("suppliers/userAddedComplete");
            // close modal
            this.isModalVisible = false;
            // Get suppliers again
            const updatedSuppliers = await this.$store.dispatch('suppliers/getAllSuppliers');
            // Update table data
            this.tableData = updatedSuppliers
            // Reset serial number value in form
            this.serialNumber = "";
            // Clean ID 
            this.selectedId = null;
          }, 1500);
          
          setTimeout(() => {
            // Reset add device state
            this.$store.dispatch("devices/complete");
          }, 1800);
       }
      },
      filterTableBySearch($event) {
        // Get typed in value in input
        const query = $event.target.value.trimStart().toLowerCase();
        // Get pressed key code 
        const pressKeyCode = $event.keyCode;
        // Store length of query 
        const queryLength = query != "" ? query.length : 0;

        if(queryLength > 0) {
          // Show cancel icon
          this.isActive = true;
          // When the backspace key is pressed
          if(pressKeyCode == 8) {
            // Get value in input once again
            const value = $event.target.value.trimStart().toLowerCase();
            // Filter data 
            this.filterTableData(value);
          }
          // Filter clients data
          this.filterTableData(query)
        } else {
          // Show cancel icon
          this.isActive = false;
          // Reset table data when there is no value in input
          this.resetTableData()
        }
      },
      filterTableData(query = "") {
          // Get all clients from store
          const data = this.$store.getters['suppliers/distributors'];
          console.log('Filter:', data);
          // Filter clients by search query
          const filterdClients = data.filter(
            c => 
            c.Name.toLowerCase().startsWith(query) || 
            c.Name.toLowerCase().includes(query) ||
            c.totalTransactions.toString().toLowerCase().startsWith(query) || 
            c.totalTransactions.toString().toLowerCase().includes(query) 
            );
          // Store the filtered data as the new tatble data values 
          this.tableData = filterdClients;
      },
      resetTableData() {
        // When query length is zero input is in empty state, we get client from store
          const data = this.$store.getters['suppliers/distributors'];
          // Store clients from store and update table data
          this.tableData = data;
      },
      cancelFilterBySearch() {
        // Get the search input and set it to null/empty
        const input = this.$refs.filterinput.$el.value = "";
        // Remove focused class
        this.isFocused = false;
        // Remove active class
        this.isActive = false;
        // Reset table data
        this.resetTableData();
      },
      removeCancelSearchIcon($event) {
        // Get typed in value in input
        const queryLength = $event.target.value.trimStart().length; 
        // Remove cancel icons based on length value length
        if(queryLength == 0) {
          // Reverse is active
          this.isActive = false;
          // Reverse is focused
          this.isFocused = false
        }
      },
      focused() {
        // Set focused class on search input wrapper
        this.isFocused = true; 
      }
    },
    async mounted() {
      // Dispatch supplier
      this.$store.dispatch('suppliers/getSuppliers');
      // Dispatch channels
      this.$store.dispatch("channels/addChannels");
      // Store transactions in master state variable
      // Get distributors from store & put them in tableData property
      this.tableData = this.$store.getters['suppliers/distributors'];
      console.log('Suppliers:', this.tableData)
    }
  }
</script>
<style>
.buttons {
  text-align: center;
  margin-bottom: 10px;
}

   .user-modal {
    display: none;
  } 

  .cancel {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #d2e3ee;
  }

    .cancel:hover {
      background-color: #d2e3ee;
      border-color: #d2e3ee;
    }

    .icon-wrapper {
          width: 60px;
    height: 60px;
    background: #f7941d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 5px;
    }

        .icon-wrapper i {
              font-size: 30px;
              color: #fff;
        }


 .success-message {
   text-align: center;
   margin: 0 auto;
       margin-top: 10px;
 }

     .success-message p {
        color: #f7941d;
        margin-top:  15px;
      }

.report-times {
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
}

.report-times .title {
  color: #f7941d;
  font-size: 12px;
}

.devices {
  padding: 0;
    margin: 0;
    margin-bottom: 20px;
    list-style: none;
        padding: 0 5px;
        border-bottom: 1px solid #EBEEF5;
            margin-top: 15px;
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
}

.devices .device {
      display: flex;
    justify-content: space-between;
border-top: 1px solid #EBEEF5;
padding: 10px 0;
}

.devices .device button {
      color: #fff;
    background-color: #f1f1f1;
    border-color: #f7941d;
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.devices .device button i{
  color: #f7941d;
}

.message {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        margin: 30px 0 50px;
}

.message-icon {
      width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 50%;
    
    margin-bottom: 10px;

}

.message.delete {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.delete .message-icon {
  background: #f5f5f5;
}

.message.delete .message-icon i {
color: #f7941d;
    margin-top: -4px;
}

.message-icon i {
      font-size: 30px;
}

.message span { 
  color: #525f7f;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 20px;
}


.form-control:focus , .form-control:active {
  background: #fff  !important;
}


.label {
  font-size: 12px;
}

.periods {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

 span.time {
    background: #f7941d;
    border-radius: 25px;
    font-size: 12px;
    color: #fff !important;
    position: relative;
    max-width: 75px;
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 600;
        display: flex;
    min-height: 30px;
        min-width: 75px;
            margin-right: 10px;

    margin-bottom: 7px;

}

 span.time .text {
       padding-left: 5px;
           line-height: 0 !important;
               display: inline-block;
    margin-bottom: -1px;
    padding-left: 5px;
 }


.time  .remove {
  display: flex;
    border-radius: 50%;
    background: #fff;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 5px;
    cursor: pointer;
    color: #f7941d;
        justify-content: center;
    align-items: center;
}


.time-error {
      color: #f7941d;
    font-size: 12px;
}


#search-wrapper {
  position: relative;
  margin: 0;
}

#search-wrapper.focused input {
  border-color: #f7941d
}

#search-wrapper.active .clear-search {
  display: flex;
}

#search-wrapper .clear-search {
    padding-top: 2px;
    position: absolute;
    top: 50%;
    right: 15px;
    display: flex;
    width: 20px;
    height: 20px;
    background: #f1f1f1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
}
#search-wrapper .clear-search i {
  font-size: 13px;
  line-height: none;
  padding: 0;
  margin: 0;
  color: #525f7f
}
    

</style>
